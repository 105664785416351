<template>
	<Layout>
		<v-container fluid>
			<v-row v-if="loading">
				<v-col cols="10">
					<progress-component />
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col>
					<h2 class="mb-4"> <v-icon large>mdi-file-document-multiple-outline</v-icon> INSCRIPCIONES</h2>
					<h3>Próximo Evento</h3>
					<h4 class="blue-grey--text">{{evento.titulo}}</h4>
					<h6 class="grey--text" v-html="evento.subtitulo"/>
				</v-col>
				<v-col cols="12">
					<list-component :event="evento" />
				</v-col>
			</v-row>
		</v-container>
	</Layout>
</template>

<script>
	import axios from 'axios';
  import store from '@/store'
	import ProgressComponent from '@/components/base/Progress.vue'
	import ListComponent from '../../components/inscripcion/ListInscripcionComponent.vue';
  const header = {"Token": store.state.userModule.token}
  const configuration = {headers: header}
	export default {
		name: 'NextEvent',
		components: {
			ProgressComponent,
			ListComponent
		},
		data: () => ({
			loading: false,
			evento: {
				_id: '',
				titulo: '',
				subtitulo: '',
				slug: ''
			},
			dialog: false,

		}),
		mounted(){
			this.loading = true
			this.createBread()
			this.getEvento()
		},

		methods: {
			createBread(){
				this.$store.dispatch('setBread',[{
					text: `Inscripciones Próximo Evento`,
					disabled: true,
					href: '#',
				}])
			},
			async getEvento(){
				try {
					const response = await axios.get('evento/query-next', configuration)
					this.evento = response.data.data;
				} catch (e) {
					console.log(e.message)
				} finally {
					this.loading = false
				}
			},


		} //methods

		// methods: {
		// 	async getEvento(){
		// 		let me = this;
		// 		try {
		// 			const response = await axios.get('evento/query-next', configuration)
		// 			me.evento = response.data.data;
		// 			console.log(me.evento)
		// 		} catch (e) {
		// 			console.log(e.message)
		// 		} finally {
		// 			me.loadingInscripciones = false
		// 		},
		// 	},
		// 	createBread(){
		// 		this.$store.dispatch('setBread',[
		// 			{
		// 				text: `Inscripciones Próximo Evento`,
		// 				disabled: true,
		// 				href: '#',
		// 			},
		// 		]);
		// 	}
		// }
	}
</script>

<style lang="scss" scoped>

</style>